/* 21. Preloader */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #50097c;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.loadingimage {
  align-items: center;
  justify-content: center;  
  height: 50%;
  width: 50%;
  margin-left: 20%;
  animation-name: rotate;
  animation-duration: 4s; 
  animation-iteration-count: infinite; 
  animation-timing-function: linear; 
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.progress-bar {
  height: 4px;
  width: 100%;
  background-image: linear-gradient(45deg, #7727e5, #b36bb3, #d1a77b, #e0e21b);
}